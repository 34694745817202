import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, ButtonGroup, Table, Container } from 'reactstrap';
import AppNavbar from './AppNavbar';

const KnowledgeAreaEdit = () => {
  const [knowledgeArea, setKnowledgeArea] = useState([]);
  const [catalogueCollections, setCatalogueCollections] = useState([]);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    setLoading(true);
    fetch(`/prod/knowledge-area/${id}`)
      .then(response => response.json())
      .then(data => {
        setKnowledgeArea(data);
        setCatalogueCollections(data.catalogueCollections);
        setLoading(false);
      })
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <AppNavbar/>
      <Container fluid>
        <h3>{knowledgeArea.name}</h3>
        <Table className="mt-4">
          <thead>
          <tr>
            <th width="20%">Name</th>
            <th width="20%">Description</th>
            <th width="40%">Catalogues</th>
          </tr>
          </thead>
          <tbody>
{catalogueCollections ? catalogueCollections.map((catalogueCollection, i) => <tr key={i}>
      <td style={{whiteSpace: 'nowrap'}}>{catalogueCollection.name}</td>
      <td>{catalogueCollection.description}</td>
      <td>{catalogueCollection.catalogues.map(catalogue => {
        return <div key={catalogue.id}>{catalogue.name}</div>
      })}</td>
      <td>
        <ButtonGroup>
          <Button size="sm" color="primary" tag={Link} to={"/catalogueCollection/" + knowledgeArea.id + "/" + catalogueCollection.id}>Navigate</Button>
        </ButtonGroup>
      </td>
    </tr>) : null}
          </tbody>
        </Table>
      </Container>
    </div>
  )
};

export default KnowledgeAreaEdit;