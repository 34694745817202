import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, ButtonGroup, Table, Container } from 'reactstrap';
import AppNavbar from './AppNavbar';

const CatalogueCollection = () => {
  const [catalogueCollection, setCatalogueCollection] = useState([]);
  const [catalogues, setCatalogues] = useState([]);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const { id2 } = useParams();

  useEffect(() => {
    setLoading(true);
    fetch(`/prod/catalogue-collection/${id}/${id2}`)
      .then(response => response.json())
      .then(data => {
        setCatalogueCollection(data);
        setCatalogues(data.catalogues);
        setLoading(false);
      })
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <AppNavbar/>
      <Container fluid>
        <h3>{catalogueCollection.name}</h3>
        <Table className="mt-4">
          <thead>
          <tr>
            <th width="20%">Name</th>
            <th width="20%">Description</th>
            <th width="40%">Catalogues</th>
          </tr>
          </thead>
          <tbody>
{catalogues ? catalogues.map(catalogue => <tr key = {catalogue.id}>
      <td style={{whiteSpace: 'nowrap'}}>{catalogue.name}</td>
      <td>{catalogue.description}</td>
      <td key = {catalogue}>
        <ButtonGroup>
          <Button size="sm" color="primary" tag={Link} to={"/catalogue/" + catalogueCollection.kid + "/" + catalogueCollection.id + "/" + catalogue.id}>Navigate</Button>
        </ButtonGroup>
      </td>
    </tr>) : null}
          </tbody>
        </Table>
      </Container>
    </div>
  )
};

export default CatalogueCollection;