import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, ButtonGroup, Table, Container } from 'reactstrap';
import AppNavbar from './AppNavbar';

function PrettyStyle({ style }) {
  if (style==="mc") {
	return "Multiple-choice";
  }
  if (style==="w") {
	return "Written";
  }
  else {
	return "Point";
  }
}

const Catalogue = () => {
  const [catalogue, setCatalogue] = useState([]);
  const [variants, setVariants] = useState([]);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const { id2 } = useParams();
  const { id3 } = useParams();

  useEffect(() => {
    setLoading(true);
    fetch(`/prod/catalogue/${id}/${id2}/${id3}`)
      .then(response => response.json())
      .then(data => {
        setCatalogue(data);
        setVariants(data.cqvs);
        setLoading(false);
      })
  }, []);
  
  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <AppNavbar/>
      <Container fluid>
        <h3>{catalogue.name}</h3>
        <Table className="mt-4">
          <thead>
          <tr>
            <th width="30%">Name</th>
            <th width="20%">Test</th>
            <th width="20%">Stats</th>
            <th width="30%">Talent</th>
          </tr>
          </thead>
          <tbody>
{variants ? variants.map(variant => <tr key = {variant.id}>
      <td style={{whiteSpace: 'nowrap'}}>{variant.askName}-{variant.responseName}:<PrettyStyle style = {variant.style} /></td>
      <td>
          <Button size="sm" color="primary" tag={Link} to={"/stats/" + catalogue.kid + "/" + catalogue.ccid + "/" + catalogue.id + "/" + variant.id}>Stats</Button>
      </td>
      <td>
          <Button size="sm" color="primary" tag={Link} to={"/test/" + catalogue.kid + "/" + catalogue.ccid + "/" + catalogue.id + "/" + variant.id}>Test</Button>
      </td>
      <td>
        {variant.talent}
        <ButtonGroup>
          <Button size="sm" color="primary" tag={Link} to={"/stats/" + catalogue.kid + "/" + catalogue.ccid + "/" + catalogue.id + "/" + variant.id}>Stats</Button>
          <Button size="sm" color="primary" tag={Link} to={"/test/" + catalogue.kid + "/" + catalogue.ccid + "/" + catalogue.id + "/" + variant.id}>Test</Button>
        </ButtonGroup>
      </td>
    </tr>) : null}
          </tbody>
        </Table>
      </Container>
    </div>
  )
};

export default Catalogue;