import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Container, Table } from 'reactstrap';
import AppNavbar from './AppNavbar';
import { Link } from 'react-router-dom';

const KnowledgeAreaList = () => {

  const [knowledgeAreas, setKnowledgeAreas] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log("using effect!!!");
    setLoading(true);
 
    fetch('/prod/knowledge-areas')
      .then(response => response.json())
      .then(responseJson => {
        console.log("response"+responseJson);
        this.setState({
          data: responseJson,
        });
      })
      .then(data => {
        setKnowledgeAreas(data);
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  const knowledgeAreaList = knowledgeAreas.map(knowledgeArea => {
    return <tr key={knowledgeArea.id}>
      <td style={{whiteSpace: 'nowrap'}}>{knowledgeArea.name}</td>
      <td>{knowledgeArea.description}</td>
      <td>{knowledgeArea.catalogueCollections.map(catalogueCollection => {
        return <div key={catalogueCollection.id}>{catalogueCollection.name}</div>
      })}</td>
      <td>
        <ButtonGroup>
          <Button size="sm" color="primary" tag={Link} to={"/knowledgeArea/" + knowledgeArea.id}>Navigate</Button>
        </ButtonGroup>
      </td>
    </tr>
  });

  return (
    <div>
      <AppNavbar/>
      <Container fluid>
        <div className="float-end">
          <Button color="success" tag={Link} to="/knowledgeAreas/new">Add KnowledgeArea</Button>
        </div>
        <h3>My CSTM</h3>
        <Table className="mt-4">
          <thead>
          <tr>
            <th width="20%">Name</th>
            <th width="20%">Description</th>
            <th width="40%">Catalogue Collections</th>
          </tr>
          </thead>
          <tbody>
          {knowledgeAreaList}
          </tbody>
        </Table>
      </Container>
    </div>
  );
};

export default KnowledgeAreaList;